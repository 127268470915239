<script>
import { h, Transition } from 'vue';

export default {
  setup(_props, { slots }) {
    const className = 'action-button';
    if(slots.default()?.[0].type?.name === 'RouterLink') {
      return () => h(Transition, { mode: 'out-in', class: className, }, slots.default());
    }
    return () => h('button', { class: className }, slots.default());
  },
};
</script>

<style lang="scss" scoped>
@import '~@/sass/00_abstracts/variables';

.action-button {
  width: 220px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 1.2;
  background: #607D8A;
  border-radius: 20px;
  box-shadow: none;
  border: none;
  padding: 8px 15px;
  margin: 100px auto 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    background: #aaa;
    color: $c-text-white;
  }
}
</style>
