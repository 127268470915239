<template>
  <AddToCalendar
    class="add-to-calendar-button"
    title="Mao & Tsou"
    location="孫立人將軍官邸（陸軍聯誼廳）, 100台灣台北市中正區南昌路一段136號"
    details="鄒適齊 & 茅雅媛 婚宴\n @孫立人將軍官邸"
    :start="new Date(2022,7,13,18)"
    :end="new Date(2022,7,13,21)"
  >
    加入日曆／Save the Date
  </AddToCalendar>
</template>

<script>
import { onMounted } from 'vue';
import AddToCalendar from '@/components/AddToCalendar.vue';

export default ({
  components: {
    AddToCalendar,
  },
  props: {},
  setup() {
    onMounted(() => {});
  },
});
</script>

<style lang="scss">
@import '~@/sass/00_abstracts/variables';

.add-to-calendar-button {
  color: #607d8a;
  font-size: 2rem;
  text-align: center;
  background: #FFFFFF;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  padding: 10px 15px;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    background: #aaa;
    color: $c-text-white;
  }
}
</style>
