<template>
  <span class="scroll-hint"></span>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.scroll-hint {
  width: 30px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 50px;

  &::before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: #fff;
    border-radius: 100%;
    animation: scrollHint 2s infinite;
    box-sizing: border-box;
  }
}

@keyframes scrollHint {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>
