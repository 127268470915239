class WaveLine {
  constructor ({ color, lambda, nodes, cvs}) {
    this.color = color;
    this.lambda = lambda;
    this.nodes = [];

    for (let i = 0; i <= nodes + 2; i++) {
      var temp = [(i - 1) * cvs.width / nodes, 0, Math.random() * 200, .3];
      this.nodes.push(temp);
    }
  }
}

export class Wave {
  constructor ({ el }) {
    const cvs = document.createElement('canvas');
    const ctx = cvs.getContext('2d');

    el.appendChild(cvs);

    this.cvs = cvs;
    this.ctx = ctx;

    this.waves = [];
    this.waveHeight = 60;
    this.colors = ['#f80000', '#00f800', '#0000f8'];
    this.nodes = [];
  }

  init() {
    this.resizeCanvas(this.cvs, document.documentElement.clientWidth, 200);

    window.addEventListener('resize', () => {
      this.resizeCanvas(this.cvs, document.documentElement.clientWidth, 200);
    });

    for (let i = 0; i < 3; i++) {
      this.waves.push(new WaveLine({
        colors: this.colors[i],
        lambda: 1,
        nodes: 6,
        cvs: this.cvs,
      }));
    }

    this.update();
  }

  update() {
    // const fill = '#bdcddf';
    const fill = '#aacddf';
    this.ctx.fillStyle = fill;
    this.ctx.globalCompositeOperation = 'source-over';
    this.ctx.fillRect(0, 0, this.cvs.width, this.cvs.height);
    this.ctx.globalCompositeOperation = 'screen';

    for (let i = 0; i < this.waves.length; i++) {
      for (let j = 0; j < this.waves[i].nodes.length; j++) {
        this.bounce(this.waves[i].nodes[j]);
      }
      this.drawWave(this.waves[i]);
    }
    this.ctx.fillStyle = fill;
    requestAnimationFrame(() => this.update());
  }

  bounce(nodeArr) {
    nodeArr[1] = (this.waveHeight / 2) * Math.sin(nodeArr[2] / 20) + this.cvs.height / 2;
    nodeArr[2] = nodeArr[2] + nodeArr[3];
  }

  drawWave (obj) {
    const diff = (a, b) => (b - a) / 2 + a;

    this.ctx.fillStyle = obj.color;
    this.ctx.beginPath();
    this.ctx.moveTo(0, this.cvs.height);
    this.ctx.lineTo(obj.nodes[0][0], obj.nodes[0][1]);

    for (let i = 0; i < obj.nodes.length; i++) {
      if (obj.nodes[i+1]) {
        this.ctx.quadraticCurveTo(
          obj.nodes[i][0],obj.nodes[i][1],
          diff(obj.nodes[i][0],obj.nodes[i+1][0]),diff(obj.nodes[i][1],obj.nodes[i+1][1])
        );
      }
      else {
        this.ctx.lineTo(obj.nodes[i][0],obj.nodes[i][1]);
        this.ctx.lineTo(this.cvs.width,this.cvs.height);
      }
    }
    this.ctx.closePath();
    this.ctx.fill();
  }

  resizeCanvas(canvas, width, height) {
    if (width && height) {
      canvas.width = width;
      canvas.height = height;
    }
    // else {
    //   if (window.innerWidth > 1920) {
    //     canvas.width = window.innerWidth;
    //   }
    //   else {
    //     canvas.width = 1920;
    //   }
    //
    //   canvas.height = this.waveHeight;
    // }
  }
}
