<template>
  <div class="menu-wrap"
    :class="[
      isOpen && 'is-open',
    ]"
  >
    <NavButton @click="toggleList" />
    <nav
      class="menu"
      :class="[
        isOpen && 'is-open',
      ]"
    >
      <Wave />
      <ul class="menu__list">
        <li class="item"><a href="#about">關於我們<br>／About Us</a></li>
        <li class="item"><a href="#vision">婚宴理念<br>／Our Wedding Vision</a></li>
        <li class="item"><a href="#info">更多資訊<br>／More Information</a></li>
        <li class="item"><router-link to="form">立即填表<br>／RSVP</router-link></li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {
  defineAsyncComponent,
} from 'vue';

export default {
  data() {
    return {
      isOpen: false,
    };
  },
  components: {
    Wave: defineAsyncComponent(() => import('@/components/Wave.vue')),
    NavButton: defineAsyncComponent(() => import('@/components/NavButton.vue')),
  },
  mounted() {
    const self = this;
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
        self.isOpen = false;
      });
    });
  },
  methods: {
    toggleList() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-wrap {
  overflow: hidden;
  position: sticky;
  top: 0;
  max-height: 80px;
  transition: 1.2s ease-in-out;
  will-change: max-height;

  &.is-open {
    max-height: 1200px;
    margin-bottom: -170px;
  }

  @media (min-width: 600px) {
    max-height: none;
  }
}

.menu {
  padding: 0;
  background: white;
  transition: 1.2s ease-in-out;
  top: -170px;
  position: relative;
  overflow: hidden;
  max-height: 0;
  will-change: max-height;

  &.is-open {
    max-height: 1200px;
  }

  @media (min-width: 600px) {
    top: 0;
    max-height: none;
    position: relative;
    z-index: -2;
  }

  &__list {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    font-size: 2rem;
    flex-direction: column;
    margin-top: 60px;

    @media (min-width: 600px) {
      flex-direction: row;
      display: flex;
      margin-top: 0;
    }

    .item {
      padding: 1em 1.4em;
      flex: 1;
    }
  }
}
</style>
