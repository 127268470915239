<template>
  <footer class="footer">
    <div class="copyright">Copyright © 2022 Mao & Tsou. All Rights Reserved.</div>
  </footer>
</template>

<style lang="scss">
@import '~@/sass/00_abstracts/variables';

.footer {
  background-color: #282b2a;
  color: $c-text-white;
  height: 50px;
  padding: 20px 0;
}

.copyright {
  font-size: 1.2rem;
  text-align: center;
}
</style>
