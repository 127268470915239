<template>
  <section class="sec">
    <h2 :id="headingId" class="sec__title">{{ title }}</h2>
    <div class="sec__note" v-if="$slots.note">
      <slot name="note" class="sec__note" />
    </div>
    <div
      class="sec__content"
      :style="cssVars"
    >
      <slot name="content" />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    headingId: {
      type: String,
      required: true,
    },
    direction: {
      type: String,
      default: 'row',
      validator(value) {
        return ['row', 'row-reverse', 'column', 'column-reverse'].indexOf(value) !== -1;
      }
    },
  },
  setup(props) {
    const cssVars = {
      '--direction': props.direction,
    };
    return { cssVars };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/sass/00_abstracts/variables';

.sec {
  font-size: 1.8rem;
  max-width: 1600px;
  margin: auto;
  padding: 20px;
  margin-bottom: 60px;

  @media (min-width: 600px) {
    margin-bottom: 60px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    line-height: 1.7;
    font-family: $font-default;

    @media (min-width: 600px) {
      gap: 60px;
      flex-direction: var(--direction);
    }
  }

  &__note {
    color: #939393;
    margin-top: -20px;
    margin-bottom: 30px;
    font-size: 1.4rem;
    line-height: 1.5;
  }

  &__title {
    font-size: 2.4rem;
    font-weight: normal;
    margin-bottom: 30px;
    padding-top: 1em;

    @media (min-width: 600px) {
      font-size: 3.6rem;
      scroll-margin-top: 80px;
    }
  }
}
</style>
