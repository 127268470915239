<template>
  <button
    class="add-to-calendar-button"
    @click="windowOpen(getCalendarUrl('google'))"
  >
    <slot />
  </button>
</template>

<script>
function formatString(string) {
  return encodeURIComponent(string)
    .replace(/%20/g, '+')
    .replace(/%5Cn\+/g, '%0A');
}

function formatDate(date) {
  return date
    ? date.toISOString().replace(/-|:|\.\d+/g, '')
    : null;
}

const calendars = {
  google: {
    url: 'http://www.google.com/calendar/render?action=TEMPLATE&trp=false',
    getParameters ({ title, location, details, start, end } = {}) {
      const parameters = {
        text: title,
        location,
        details,
      };

      if (start && end) {
        parameters.dates = `${start}/${end}`;
      }

      return parameters;
    }
  },
};

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    location: {
      type: String,
      default: '',
    },
    details: {
      type: String,
      default: '',
    },
    start: {
      type: Date,
      default: null,
    },
    end: {
      type: Date,
      default: null,
    },
  },
  methods: {
    getCalendarUrl(calendar) {
      let url = calendars[calendar].url;
      const parameters = calendars[calendar].getParameters({
        title: formatString(this.title),
        location: formatString(this.location),
        details: formatString(this.details),
        start: formatDate(this.start),
        end: formatDate(this.end)
      });

      for(let key in parameters) {
        if(parameters[key]) {
          url += `&${key}=${parameters[key]}`;
        }
      }

      return url;
    },
    windowOpen(target) {
      return window.open(target);
    },
  },
  setup() {
    return {};
  },
};
</script>
