<template>
  <div
    ref="canvasWrap"
    class="canvas-wrap"
    :class="[reverse && 'canvas-wrap--reverse']"
  />
</template>

<script>
import { ref, onMounted } from 'vue';
import { Wave } from '@/helpers/wave.js';

export default {
  setup() {
    const canvasWrap = ref('canvasWrap');

    onMounted(() => {
      const cvsWrap = canvasWrap.value;
      const wave = new Wave({
        el: cvsWrap,
        nodeLimit: 6,
      });
      wave.init();
    });

    return { canvasWrap };
  },
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.canvas-wrap {
  position: absolute;
  z-index: -1;

  &--reverse {
    transform: rotate(180deg);
    bottom: 0;
  }
}
</style>
